import { PubSub, PubsubEvents } from 'Services/core__services';

// Function to check the user has clicked outside the left category section
export const handleClickOutside = event => {
  const wrapper = document.getElementById('left-wrapper');
  const hamburgerWrapper = document.getElementById('hamburger');
  // condition to check the user has clicked outside the left category section
  if (
    wrapper &&
    !wrapper.contains(event.target) &&
    hamburgerWrapper !== event.target
  ) {
    //removing the active class from left wrapper
    if (document.querySelector('#toplevel')) {
      document.querySelector('body').classList.remove('mb-hide');
    }

    if (document.querySelector('.ml-active')) {
      document
        .querySelector('.main-left-wrapper')
        .classList.remove('ml-active');
      wrapper.style.width = `0px`;
    }
  }
};

export const closeCategory = () => {
  const wrapper = document.getElementById('left-wrapper');
  if (document.querySelector('#toplevel')) {
    document.querySelector('body').classList.remove('mb-hide');
  }

  if (document.querySelector('.ml-active')) {
    document.querySelector('.main-left-wrapper').classList.remove('ml-active');
    wrapper.style.width = `0px`;
    PubSub.emit(PubsubEvents.CLOSE_CATEGORY_LIST);
  }
};
export const bettingPools = { id: 1, name: 'Pools', ref: 'betting-pools' };
