export const categoryApiResponse = {
  category: [
    {
      id: 2,
      name: 'Horse Racing',
      ref: 'HORSES',
    },
    {
      id: 10,
      name: 'Cricket',
      ref: 'CRICKET',
    },
    {
      id: 13,
      name: 'Ice Hockey',
      ref: 'ICE_HOCKEY',
    },
    {
      id: 32,
      name: 'Table Tennis',
      ref: 'TABLE_TENNIS',
    },
    {
      id: 31,
      name: 'Mixed Martial Arts',
      ref: 'MMA',
    },
    {
      id: 28,
      name: 'Sumo',
      ref: 'BANDY',
    },
    {
      id: 6,
      name: 'Darts',
      ref: 'DARTS',
    },
    {
      id: 22,
      name: 'Cycling',
      ref: 'CYCLING',
    },
    {
      id: 27,
      name: 'Virtual',
      ref: 'VIRTUAL',
    },
    {
      id: 23,
      name: 'Esports',
      ref: 'ESPORTS',
    },
    {
      id: 15,
      name: 'Baseball',
      ref: 'BASEBALL',
    },
    {
      id: 4,
      name: 'Boxing',
      ref: 'BOXING',
    },
    {
      id: 3,
      name: 'Tennis',
      ref: 'TENNIS',
    },
    {
      id: 12,
      name: 'Basketball',
      ref: 'BASKETBALL',
    },
    {
      id: 1,
      name: 'Football',
      ref: 'SOCCER',
    },
    {
      id: 9,
      name: 'Snooker',
      ref: 'SNOOKER',
    },
    {
      id: 5,
      name: 'Golf',
      ref: 'GOLF',
    },
    {
      id: 14,
      name: 'Volleyball',
      ref: 'VOLLEYBALL',
    },
    {
      id: 21,
      name: 'Motor Sport',
      ref: 'MOTORSPORT',
    },
    {
      id: 7,
      name: 'Rugby Union',
      ref: 'RUGBY_UNION',
    },
    {
      id: 25,
      name: 'Greyhounds',
      ref: 'DOGS',
    },
    {
      id: 11,
      name: 'American Football',
      ref: 'AMERICAN_FOOTBALL',
    },
    {
      id: 17,
      name: 'Handball',
      ref: 'HANDBALL',
    },
  ],
  data: {
    res: {
      category: [
        {
          id: 2,
          name: 'Horse Racing',
          ref: 'HORSES',
        },
        {
          id: 10,
          name: 'Cricket',
          ref: 'CRICKET',
        },
        {
          id: 13,
          name: 'Ice Hockey',
          ref: 'ICE_HOCKEY',
        },
        {
          id: 32,
          name: 'Table Tennis',
          ref: 'TABLE_TENNIS',
        },
        {
          id: 31,
          name: 'Mixed Martial Arts',
          ref: 'MMA',
        },
        {
          id: 28,
          name: 'Sumo',
          ref: 'BANDY',
        },
        {
          id: 6,
          name: 'Darts',
          ref: 'DARTS',
        },
        {
          id: 22,
          name: 'Cycling',
          ref: 'CYCLING',
        },
        {
          id: 27,
          name: 'Virtual',
          ref: 'VIRTUAL',
        },
        {
          id: 23,
          name: 'Esports',
          ref: 'ESPORTS',
        },
        {
          id: 15,
          name: 'Baseball',
          ref: 'BASEBALL',
        },
        {
          id: 4,
          name: 'Boxing',
          ref: 'BOXING',
        },
        {
          id: 3,
          name: 'Tennis',
          ref: 'TENNIS',
        },
        {
          id: 12,
          name: 'Basketball',
          ref: 'BASKETBALL',
        },
        {
          id: 1,
          name: 'Football',
          ref: 'SOCCER',
        },
        {
          id: 9,
          name: 'Snooker',
          ref: 'SNOOKER',
        },
        {
          id: 5,
          name: 'Golf',
          ref: 'GOLF',
        },
        {
          id: 14,
          name: 'Volleyball',
          ref: 'VOLLEYBALL',
        },
        {
          id: 21,
          name: 'Motor Sport',
          ref: 'MOTORSPORT',
        },
        {
          id: 7,
          name: 'Rugby Union',
          ref: 'RUGBY_UNION',
        },
        {
          id: 25,
          name: 'Greyhounds',
          ref: 'DOGS',
        },
        {
          id: 11,
          name: 'American Football',
          ref: 'AMERICAN_FOOTBALL',
        },
        {
          id: 17,
          name: 'Handball',
          ref: 'HANDBALL',
        },
      ],
    },
  },
};

export const subCategoryApiResponse = {
  list: {
    id: 4,
    name: 'Boxing',
    ref: 'BOXING',
  },
  favouriteList: {
    favourite: true,
    id: 20,
    name: 'Australian Rules',
    ref: 'AUSTRALIAN_RULES',
  },
  favList: [10, 2, 31, 20],
  selectedCategory: 'Horse Racing',
  subCategory: {
    category: [
      {
        id: 2,
        name: 'Horse Racing',
        ref: 'HORSES',
        subcat: [
          {
            featured: false,
            id: 4719,
            name: 'Sam Houston Race Park',
            ref: 'QSH',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 741,
            name: 'Turf Paradise',
            ref: 'XTP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 698,
            name: 'Delta Downs',
            ref: 'XDD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 761,
            name: 'Ludlow',
            ref: 'LUD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 595,
            name: 'Newcastle',
            ref: 'NCS',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1494,
            name: 'Vaal',
            ref: 'VAA',
          },
          {
            featured: false,
            id: 4426,
            name: 'Mornington',
            ref: 'MGT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 804,
            name: 'Santa Anita',
            ref: 'XST',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 711,
            name: 'Aqueduct',
            ref: 'XAQ',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 701,
            name: 'Tampa Bay Downs',
            ref: 'XTM',
          },
          {
            featured: false,
            id: 4501,
            name: 'Canberra',
            ref: 'CBR',
          },
          {
            featured: false,
            id: 4048,
            name: 'Pau',
            ref: 'PAU',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 802,
            name: 'Santa Anita',
            ref: 'QST',
          },
          {
            featured: false,
            id: 4357,
            name: 'Mauquenchy',
            ref: 'MAQ',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1513,
            name: 'Moonee Valley',
            ref: 'MNV',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1511,
            name: 'Fairview',
            ref: 'FAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 1412,
            name: 'Sam Houston Race Park',
            ref: 'XSH',
          },
          {
            featured: false,
            id: 3885,
            name: 'Port Macquarie',
            ref: 'PMQ',
          },
          {
            featured: false,
            id: 3889,
            name: 'Pornichet',
            ref: 'POR',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 641,
            name: 'Lingfield',
            ref: 'LIN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 586,
            name: 'Taunton',
            ref: 'TAU',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 752,
            name: 'Ante Post',
            ref: 'AP',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 776,
            name: 'Haydock',
            ref: 'HDO',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 798,
            name: 'Wincanton',
            ref: 'WCN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 588,
            name: 'Chelmsford City',
            ref: 'CFC',
          },
          {
            featured: false,
            id: 2729,
            name: 'Saint-Cloud',
            ref: 'SCC',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 596,
            name: 'Dundalk',
            ref: 'DUN',
          },
          {
            featured: false,
            id: 7129,
            name: 'Penn National',
            ref: 'XPN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 772,
            name: 'Ascot',
            ref: 'ASC',
          },
          {
            countryCode: 'IE',
            countryName: 'Ireland',
            featured: false,
            id: 695,
            name: 'Navan',
            ref: 'NAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 803,
            name: 'Golden Gate Fields',
            ref: 'XGG',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 704,
            name: 'Charles Town',
            ref: 'QCT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 706,
            name: 'Laurel Park',
            ref: 'XLP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 700,
            name: 'Penn National',
            ref: 'QPN',
          },
          {
            featured: false,
            id: 4465,
            name: 'Yonkers',
            ref: 'QYO',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 702,
            name: 'Gulfstream',
            ref: 'XGF',
          },
          {
            featured: false,
            id: 4479,
            name: 'Dalby',
            ref: 'DBY',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 625,
            name: 'Musselburgh',
            ref: 'MUS',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 801,
            name: 'Golden Gate Fields',
            ref: 'QGG',
          },
          {
            featured: false,
            id: 10340,
            name: 'The Meadows Harness',
            ref: 'XMH',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1512,
            name: 'Canterbury',
            ref: 'CBY',
          },
          {
            featured: false,
            id: 3939,
            name: 'Vincennes',
            ref: 'VIN',
          },
          {
            featured: false,
            id: 4370,
            name: 'Cagnes-sur-Mer',
            ref: 'CAG',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1639,
            name: 'Esperance',
            ref: 'ESP',
          },
        ],
      },
    ],
    status: {
      method: '/fsb-api-rest/bet/category/HORSES.fsb',
      returnCode: 'SUCCESS',
      timestamp: 1611232311844,
      value: 'Success',
    },
  },
  subCategoryFav: {
    category: [
      {
        id: 2,
        name: 'Horse Racing',
        ref: 'HORSES',
        favourite: true,
        subcat: [
          {
            featured: false,
            id: 4719,
            name: 'Sam Houston Race Park',
            ref: 'QSH',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 741,
            name: 'Turf Paradise',
            ref: 'XTP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 698,
            name: 'Delta Downs',
            ref: 'XDD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 761,
            name: 'Ludlow',
            ref: 'LUD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 595,
            name: 'Newcastle',
            ref: 'NCS',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1494,
            name: 'Vaal',
            ref: 'VAA',
          },
          {
            featured: false,
            id: 4426,
            name: 'Mornington',
            ref: 'MGT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 804,
            name: 'Santa Anita',
            ref: 'XST',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 711,
            name: 'Aqueduct',
            ref: 'XAQ',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 701,
            name: 'Tampa Bay Downs',
            ref: 'XTM',
          },
          {
            featured: false,
            id: 4501,
            name: 'Canberra',
            ref: 'CBR',
          },
          {
            featured: false,
            id: 4048,
            name: 'Pau',
            ref: 'PAU',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 802,
            name: 'Santa Anita',
            ref: 'QST',
          },
          {
            featured: false,
            id: 4357,
            name: 'Mauquenchy',
            ref: 'MAQ',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1513,
            name: 'Moonee Valley',
            ref: 'MNV',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1511,
            name: 'Fairview',
            ref: 'FAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 1412,
            name: 'Sam Houston Race Park',
            ref: 'XSH',
          },
          {
            featured: false,
            id: 3885,
            name: 'Port Macquarie',
            ref: 'PMQ',
          },
          {
            featured: false,
            id: 3889,
            name: 'Pornichet',
            ref: 'POR',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 641,
            name: 'Lingfield',
            ref: 'LIN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 586,
            name: 'Taunton',
            ref: 'TAU',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 752,
            name: 'Ante Post',
            ref: 'AP',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 776,
            name: 'Haydock',
            ref: 'HDO',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 798,
            name: 'Wincanton',
            ref: 'WCN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 588,
            name: 'Chelmsford City',
            ref: 'CFC',
          },
          {
            featured: false,
            id: 2729,
            name: 'Saint-Cloud',
            ref: 'SCC',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 596,
            name: 'Dundalk',
            ref: 'DUN',
          },
          {
            featured: false,
            id: 7129,
            name: 'Penn National',
            ref: 'XPN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 772,
            name: 'Ascot',
            ref: 'ASC',
          },
          {
            countryCode: 'IE',
            countryName: 'Ireland',
            featured: false,
            id: 695,
            name: 'Navan',
            ref: 'NAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 803,
            name: 'Golden Gate Fields',
            ref: 'XGG',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 704,
            name: 'Charles Town',
            ref: 'QCT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 706,
            name: 'Laurel Park',
            ref: 'XLP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 700,
            name: 'Penn National',
            ref: 'QPN',
          },
          {
            featured: false,
            id: 4465,
            name: 'Yonkers',
            ref: 'QYO',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 702,
            name: 'Gulfstream',
            ref: 'XGF',
          },
          {
            featured: false,
            id: 4479,
            name: 'Dalby',
            ref: 'DBY',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 625,
            name: 'Musselburgh',
            ref: 'MUS',
          },
        ],
      },
    ],
    status: {
      method: '/fsb-api-rest/bet/category/HORSES.fsb',
      returnCode: 'SUCCESS',
      timestamp: 1611232311844,
      value: 'Success',
    },
  },
  subCategoryNoSub: {
    category: [
      {
        id: 2,
        name: 'Horse Racing',
        ref: 'HORSES',
        subcat: [],
      },
    ],
  },
};

export const propsData = {
  showCategories: 1,
  arrowUp: 'arrowUp',
  arrowDown: 'arrowDown',
  selectedCategory: 'Demo',
  forUpdate: false,
  url: '/fsb-api-rest/bet/category',
  accessToken: 'accessToken',
  list: 'HORSES',
  catRef: 'HORSES, TENNIS',
};

export const countryList = {
  'United States': [
    {
      countryCode: 'US',
      countryName: 'United States',
      featured: false,
      id: 1,
      name: 'NBA',
      ref: 'US_NBA',
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      featured: false,
      id: 339,
      name: 'NCAA',
      ref: 'NCAA',
    },
  ],
  Latvia: [
    {
      countryCode: 'LV',
      countryName: 'Latvia',
      featured: false,
      id: 311,
      name: 'Latvia - Estonian Basketball League',
      ref: 'LATVIA_-_ESTONIAN_BASKETBALL_LEAGUE',
    },
  ],
  France: [
    {
      countryCode: 'FR',
      countryName: 'France',
      featured: false,
      id: 516,
      name: "France Women's LfB",
      ref: 'FRANCE_WOMENS_LFB',
    },
  ],
  Australia: [
    {
      countryCode: 'AU',
      countryName: 'Australia',
      featured: false,
      id: 171,
      name: 'Australia NBL',
      ref: 'AUSTRALIA_NBL',
    },
  ],
  Austria: [
    {
      countryCode: 'AT',
      countryName: 'Austria',
      featured: false,
      id: 43,
      name: 'Austria Bundesliga',
      ref: 'AUSTRIA_BUNDESLIGA',
    },
  ],
  Spain: [
    {
      countryCode: 'ES',
      countryName: 'Spain',
      featured: false,
      id: 100,
      name: 'Spain Adecco Oro',
      ref: 'SPAIN_ADECCO_ORO',
    },
  ],
  Europe: [
    {
      countryCode: 'EU',
      countryName: 'Europe',
      featured: false,
      id: 26,
      name: 'Euroleague',
      ref: 'EUROLEAGUE',
    },
  ],
};
export const LiveEvent = {
  category: [
    {
      id: 32,
      name: 'Table Tennis',
      ref: 'TABLE_TENNIS',
      subcat: [
        {
          event: [
            {
              code: '98110',
              displayed: true,
              featured: false,
              id: 6360071,
              marketCnt: 5,
              name: 'Gerasimov, Anatolii v Chernov, Vjacheslav',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614233700000,
              sourceKey: '26101054',
              state: 'IN_PLAY',
              version: 37,
              virtual: false,
            },
            {
              code: '88513',
              displayed: true,
              featured: false,
              id: 6353926,
              marketCnt: 5,
              name: 'Klavdenkov, Alexander v Tolmachev, Ivan',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614233700000,
              sourceKey: '26098022',
              state: 'IN_PLAY',
              version: 67,
              virtual: false,
            },
          ],
          featured: false,
          id: 5154,
          name: 'Liga Pro',
          ref: 'LIGA_PRO',
        },
        {
          event: [
            {
              code: '42882',
              displayed: true,
              featured: false,
              id: 6326136,
              marketCnt: 0,
              name: 'Khurtak, Evgeniy v Gress, Aleksandr',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614081900000,
              sourceKey: '26050442',
              state: 'IN_PLAY',
              version: 97,
              virtual: false,
            },
            {
              code: '92465',
              displayed: true,
              featured: false,
              id: 6356101,
              marketCnt: 5,
              name: 'Kravchuk, Artur v Semenets Sergey',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614233700000,
              sourceKey: '26093148',
              state: 'IN_PLAY',
              version: 53,
              virtual: false,
            },
          ],
          featured: false,
          id: 4957,
          name: 'Setka Cup',
          ref: 'SETKA_CUP',
        },
        {
          event: [
            {
              code: '89228',
              displayed: true,
              featured: false,
              id: 6354192,
              marketCnt: 5,
              name: 'Grushko, Vadim v Presnyakov, Mikhail',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614232800000,
              sourceKey: '26095182',
              state: 'IN_PLAY',
              version: 162,
              virtual: false,
            },
          ],
          featured: false,
          id: 5152,
          name: 'Ukraine Win Cup',
          ref: 'UKRAINE_WIN_CUP',
        },
        {
          event: [
            {
              code: '57153',
              displayed: true,
              featured: false,
              id: 6334725,
              marketCnt: 1,
              name: 'Zaika, Bogdan v Shepel, Vasiliy',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614080700000,
              sourceKey: '26074980',
              state: 'IN_PLAY',
              version: 147,
              virtual: false,
            },
            {
              code: '90934',
              displayed: true,
              featured: false,
              id: 6355474,
              marketCnt: 5,
              name: 'Simonchuk, David v Riabukhin, Anton',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614234300000,
              sourceKey: '26098506',
              state: 'IN_PLAY',
              version: 11,
              virtual: false,
            },
          ],
          featured: false,
          id: 5755,
          name: 'TT Cup',
          ref: 'TT_CUP',
        },
      ],
    },
    {
      id: 3,
      name: 'Tennis',
      ref: 'TENNIS',
      subcat: [
        {
          event: [
            {
              code: '79825',
              displayed: true,
              featured: false,
              id: 6349053,
              marketCnt: 19,
              name: 'Christian K / Santamaria S v Duan Y-Y / Zheng S',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614232200000,
              sourceKey: '26092550',
              state: 'IN_PLAY',
              version: 185,
              virtual: false,
            },
          ],
          featured: false,
          id: 4618,
          name: 'WTA Adelaide',
          ref: 'WTA_ADELAIDE',
        },
        {
          event: [
            {
              code: '63620',
              displayed: true,
              featured: false,
              id: 6338048,
              marketCnt: 17,
              name: 'Borna Gojo v Henri Laaksonen',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614229200000,
              sourceKey: '26077206',
              state: 'IN_PLAY',
              version: 283,
              virtual: false,
            },
            {
              code: '84670',
              displayed: true,
              featured: false,
              id: 6351317,
              marketCnt: 17,
              name: 'Brayden Schnur v Prajnesh Gunneswaran',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614229200000,
              sourceKey: '26092496',
              state: 'IN_PLAY',
              version: 249,
              virtual: false,
            },
          ],
          featured: false,
          id: 2870,
          name: 'ATP Challenger Nur-Sultan',
          ref: 'ATP_CHALLENGER_NUR-SULTAN',
        },
        {
          event: [
            {
              code: '58792',
              displayed: true,
              featured: false,
              id: 6335801,
              marketCnt: 24,
              name: 'Yannick Hanfmann v Radu Albot',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614232800000,
              sourceKey: '26075510',
              state: 'IN_PLAY',
              version: 93,
              virtual: false,
            },
            {
              code: '84360',
              displayed: true,
              featured: false,
              id: 6351110,
              marketCnt: 8,
              name: 'Bambridge L / Inglot D v Cerretani J / Shamasdin A',
              providerRef: 'BETRADAR_UOF',
              scheduledStart: 1614231300000,
              sourceKey: '26083940',
              state: 'IN_PLAY',
              version: 164,
              virtual: false,
            },
          ],
          featured: false,
          id: 10610,
          name: 'ATP Singapore',
          ref: 'ATP_SINGAPORE',
        },
      ],
    },
    {
      id: 12,
      name: 'Basketball',
      ref: 'BASKETBALL',
      subcat: [
        {
          countryCode: 'AU',
          countryName: 'Australia',
          event: [
            {
              code: '76838',
              displayed: true,
              featured: false,
              id: 6347022,
              marketCnt: 17,
              name: 'Sydney Kings v New Zealand Breakers',
              providerRef: 'SSOL',
              scheduledStart: 1614232800000,
              sourceKey: 'G8ngZqrqX9XEqM77imjsNk-dI28',
              state: 'IN_PLAY',
              version: 247,
              virtual: false,
            },
          ],
          featured: false,
          id: 171,
          name: 'Australia NBL',
          ref: 'AUSTRALIA_NBL',
        },
      ],
    },
    {
      id: 1,
      name: 'Football',
      ref: 'SOCCER',
      subcat: [
        {
          event: [
            {
              code: '98195',
              displayed: true,
              featured: false,
              id: 6360131,
              marketCnt: 0,
              name:
                'Manchester City (white_boy1927) v Liverpool (slezaintima) Esports',
              providerRef: 'LSPORTS',
              scheduledStart: 1614232560000,
              sourceKey: '6596012',
              state: 'IN_PLAY',
              version: 9,
              virtual: false,
            },
            {
              code: '98234',
              displayed: true,
              featured: false,
              id: 6360152,
              marketCnt: 6,
              name:
                'Chelsea (rus_1995_lan) esports v Liverpool (slezaintima) Esports',
              providerRef: 'LSPORTS',
              scheduledStart: 1614234000000,
              sourceKey: '6596079',
              state: 'IN_PLAY',
              version: 22,
              virtual: false,
            },
            {
              code: '98227',
              displayed: true,
              featured: false,
              id: 6360148,
              marketCnt: 6,
              name:
                'Manchester United (DangerDim77) v Manchester City (white_boy1927)',
              providerRef: 'LSPORTS',
              scheduledStart: 1614234000000,
              sourceKey: '6596016',
              state: 'IN_PLAY',
              version: 32,
              virtual: false,
            },
          ],
          featured: false,
          id: 5781,
          name: 'FIFA eSports Battle Premier League',
          ref: 'FIFA_ESPORTS_BATTLE_PREMIER_LEAGUE',
        },
      ],
    },
    {
      id: 14,
      name: 'Volleyball',
      ref: 'VOLLEYBALL',
      subcat: [
        {
          event: [
            {
              code: '89291',
              displayed: true,
              featured: false,
              id: 6354241,
              marketCnt: 2,
              name: 'Turan Turkestan v Aktobe',
              providerRef: 'LSPORTS',
              scheduledStart: 1614229200000,
              sourceKey: '6594976',
              state: 'IN_PLAY',
              version: 163,
              virtual: false,
            },
          ],
          featured: false,
          id: 7948,
          name: 'Kazakhstan Higher League',
          ref: 'KAZAKHSTAN_HIGHER_LEAGUE',
        },
      ],
    },
    {
      id: 17,
      name: 'Handball',
      ref: 'HANDBALL',
      subcat: [
        {
          countryCode: 'RO',
          countryName: 'Romania',
          event: [
            {
              code: '33537',
              displayed: true,
              featured: false,
              id: 6318923,
              marketCnt: 0,
              name: 'CS Rapid Bucuresti Women v SCM Gloria Buzau Women',
              providerRef: 'SSOL',
              scheduledStart: 1614088800000,
              sourceKey: 'MCnaA2POlmpKeha3-d-QOvKHcaM',
              state: 'IN_PLAY',
              version: 411,
              virtual: false,
            },
          ],
          featured: false,
          id: 886,
          name: 'Romania Liga Nationala Women',
          ref: 'ROMANIA_LIGA_NATIONALA_WOMEN',
        },
      ],
    },
  ],
  status: {
    method: '/fsb-api-rest/bet/category.fsb',
    returnCode: 'SUCCESS',
    timestamp: 1614234399366,
    value: 'Success',
  },
};

export const wsData = {
  accessToken: '06344fb2-8205-4bfc-9d1d-ebab1cc6de15',
  accountStatus: 'ACTIVE',
  balance: 873,
  currencyCode: 'GBP',
  firstActivated: 1614865865016,
  firstName: 'Shivam',
  id: 5123,
  lastName: 'Mehrotra',
  level: 'LEVEL_ZERO',
  title: 'mr',
  userName: 'shivam',
  wallet: [
    {
      cash: 0,
      catRefRestriction: 'SOCCER',
      credit: 10,
      currencyCode: 'GBP',
      description: 'Testing',
      expires: 1617616765000,
      id: 41204,
      opened: 1617011991095,
      siteId: 0,
      state: 'OPEN',
    },
  ],
};

export const category = {
  data: {
    category: {
      category: [
        {
          id: 2,
          name: 'Horse Racing',
          ref: 'HORSES',
          favourite: true,
          subcat: [
            {
              featured: false,
              id: 4719,
              name: 'Sam Houston Race Park',
              ref: 'QSH',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 741,
              name: 'Turf Paradise',
              ref: 'XTP',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 698,
              name: 'Delta Downs',
              ref: 'XDD',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 761,
              name: 'Ludlow',
              ref: 'LUD',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 595,
              name: 'Newcastle',
              ref: 'NCS',
            },
            {
              countryCode: 'ZA',
              countryName: 'South Africa',
              featured: false,
              id: 1494,
              name: 'Vaal',
              ref: 'VAA',
            },
            {
              featured: false,
              id: 4426,
              name: 'Mornington',
              ref: 'MGT',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 804,
              name: 'Santa Anita',
              ref: 'XST',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 711,
              name: 'Aqueduct',
              ref: 'XAQ',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 701,
              name: 'Tampa Bay Downs',
              ref: 'XTM',
            },
            {
              featured: false,
              id: 4501,
              name: 'Canberra',
              ref: 'CBR',
            },
            {
              featured: false,
              id: 4048,
              name: 'Pau',
              ref: 'PAU',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 802,
              name: 'Santa Anita',
              ref: 'QST',
            },
            {
              featured: false,
              id: 4357,
              name: 'Mauquenchy',
              ref: 'MAQ',
            },
            {
              countryCode: 'AU',
              countryName: 'Australia',
              featured: false,
              id: 1513,
              name: 'Moonee Valley',
              ref: 'MNV',
            },
            {
              countryCode: 'ZA',
              countryName: 'South Africa',
              featured: false,
              id: 1511,
              name: 'Fairview',
              ref: 'FAV',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 1412,
              name: 'Sam Houston Race Park',
              ref: 'XSH',
            },
            {
              featured: false,
              id: 3885,
              name: 'Port Macquarie',
              ref: 'PMQ',
            },
            {
              featured: false,
              id: 3889,
              name: 'Pornichet',
              ref: 'POR',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 641,
              name: 'Lingfield',
              ref: 'LIN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 586,
              name: 'Taunton',
              ref: 'TAU',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 752,
              name: 'Ante Post',
              ref: 'AP',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 776,
              name: 'Haydock',
              ref: 'HDO',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 798,
              name: 'Wincanton',
              ref: 'WCN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 588,
              name: 'Chelmsford City',
              ref: 'CFC',
            },
            {
              featured: false,
              id: 2729,
              name: 'Saint-Cloud',
              ref: 'SCC',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 596,
              name: 'Dundalk',
              ref: 'DUN',
            },
            {
              featured: false,
              id: 7129,
              name: 'Penn National',
              ref: 'XPN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 772,
              name: 'Ascot',
              ref: 'ASC',
            },
            {
              countryCode: 'IE',
              countryName: 'Ireland',
              featured: false,
              id: 695,
              name: 'Navan',
              ref: 'NAV',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 803,
              name: 'Golden Gate Fields',
              ref: 'XGG',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 704,
              name: 'Charles Town',
              ref: 'QCT',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 706,
              name: 'Laurel Park',
              ref: 'XLP',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 700,
              name: 'Penn National',
              ref: 'QPN',
            },
            {
              featured: false,
              id: 4465,
              name: 'Yonkers',
              ref: 'QYO',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 702,
              name: 'Gulfstream',
              ref: 'XGF',
            },
            {
              featured: false,
              id: 4479,
              name: 'Dalby',
              ref: 'DBY',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 625,
              name: 'Musselburgh',
              ref: 'MUS',
            },
          ],
        },
      ],
      status: {
        method: '/fsb-api-rest/bet/category/HORSES.fsb',
        returnCode: 'SUCCESS',
        timestamp: 1611232311844,
        value: 'Success',
      },
    },
    liveEventCount: 89,
    cookies: {
      favouriteList: '{"DARTS":["CMB","L180_YN_LM"],"SOCCER":["1X2BTTS","DC"]}',
      AppSession: 'AppSession',
    },
  },
};

export const categoryAPI = {
  data: {
    response: {
      category: [
        {
          id: 2,
          name: 'Horse Racing',
          ref: 'HORSES',
          favourite: true,
          subcat: [
            {
              featured: false,
              id: 4719,
              name: 'Sam Houston Race Park',
              ref: 'QSH',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 741,
              name: 'Turf Paradise',
              ref: 'XTP',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 698,
              name: 'Delta Downs',
              ref: 'XDD',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 761,
              name: 'Ludlow',
              ref: 'LUD',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 595,
              name: 'Newcastle',
              ref: 'NCS',
            },
            {
              countryCode: 'ZA',
              countryName: 'South Africa',
              featured: false,
              id: 1494,
              name: 'Vaal',
              ref: 'VAA',
            },
            {
              featured: false,
              id: 4426,
              name: 'Mornington',
              ref: 'MGT',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 804,
              name: 'Santa Anita',
              ref: 'XST',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 711,
              name: 'Aqueduct',
              ref: 'XAQ',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 701,
              name: 'Tampa Bay Downs',
              ref: 'XTM',
            },
            {
              featured: false,
              id: 4501,
              name: 'Canberra',
              ref: 'CBR',
            },
            {
              featured: false,
              id: 4048,
              name: 'Pau',
              ref: 'PAU',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 802,
              name: 'Santa Anita',
              ref: 'QST',
            },
            {
              featured: false,
              id: 4357,
              name: 'Mauquenchy',
              ref: 'MAQ',
            },
            {
              countryCode: 'AU',
              countryName: 'Australia',
              featured: false,
              id: 1513,
              name: 'Moonee Valley',
              ref: 'MNV',
            },
            {
              countryCode: 'ZA',
              countryName: 'South Africa',
              featured: false,
              id: 1511,
              name: 'Fairview',
              ref: 'FAV',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 1412,
              name: 'Sam Houston Race Park',
              ref: 'XSH',
            },
            {
              featured: false,
              id: 3885,
              name: 'Port Macquarie',
              ref: 'PMQ',
            },
            {
              featured: false,
              id: 3889,
              name: 'Pornichet',
              ref: 'POR',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 641,
              name: 'Lingfield',
              ref: 'LIN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 586,
              name: 'Taunton',
              ref: 'TAU',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 752,
              name: 'Ante Post',
              ref: 'AP',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 776,
              name: 'Haydock',
              ref: 'HDO',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 798,
              name: 'Wincanton',
              ref: 'WCN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 588,
              name: 'Chelmsford City',
              ref: 'CFC',
            },
            {
              featured: false,
              id: 2729,
              name: 'Saint-Cloud',
              ref: 'SCC',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 596,
              name: 'Dundalk',
              ref: 'DUN',
            },
            {
              featured: false,
              id: 7129,
              name: 'Penn National',
              ref: 'XPN',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 772,
              name: 'Ascot',
              ref: 'ASC',
            },
            {
              countryCode: 'IE',
              countryName: 'Ireland',
              featured: false,
              id: 695,
              name: 'Navan',
              ref: 'NAV',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 803,
              name: 'Golden Gate Fields',
              ref: 'XGG',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 704,
              name: 'Charles Town',
              ref: 'QCT',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 706,
              name: 'Laurel Park',
              ref: 'XLP',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 700,
              name: 'Penn National',
              ref: 'QPN',
            },
            {
              featured: false,
              id: 4465,
              name: 'Yonkers',
              ref: 'QYO',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: false,
              id: 702,
              name: 'Gulfstream',
              ref: 'XGF',
            },
            {
              featured: false,
              id: 4479,
              name: 'Dalby',
              ref: 'DBY',
            },
            {
              countryCode: 'GB',
              countryName: 'United Kingdom',
              featured: false,
              id: 625,
              name: 'Musselburgh',
              ref: 'MUS',
            },
          ],
        },
      ],
      status: {
        method: '/fsb-api-rest/bet/category/HORSES.fsb',
        returnCode: 'SUCCESS',
        timestamp: 1611232311844,
        value: 'Success',
      },
    },
    liveEventCount: 89,
    cookies: {
      favouriteList: '{"DARTS":["CMB","L180_YN_LM"],"SOCCER":["1X2BTTS","DC"]}',
      favList: '["CMB","L180_YN_LM"]',
    },
  },
};

export const noCategoryData = {
  data: {},
};

export const ExcludeList = 'Virtual';

export const subCategoryDataApi = {
  data: {
    response: {
      category: [
        {
          id: 12,
          name: 'Basketball',
          ref: 'BASKETBALL',
          subcat: [
            {
              featured: false,
              id: 19186,
              name: 'Belgian Basketball League',
              ref: 'BELGIAN_BASKETBALL_LEAGUE',
            },
            {
              countryCode: 'AU',
              countryName: 'Australia',
              featured: false,
              id: 2053,
              name: 'Australia NBL',
              ref: 'AUSTRALIA_NBL',
            },
            {
              countryCode: 'SI',
              countryName: 'Slovenia',
              featured: false,
              id: 6027,
              name: 'Slovenia Liga Telemach',
              ref: 'SLOVENIA_LIGA_TELEMACH',
            },
            {
              countryCode: 'HU',
              countryName: 'Hungary',
              featured: false,
              id: 2089,
              name: 'Hungary NB1-A',
              ref: 'HUNGARY_NB1-A',
            },
            {
              countryCode: 'BG',
              countryName: 'Bulgaria',
              featured: false,
              id: 2090,
              name: 'Bulgaria NBL',
              ref: 'BULGARIA_NBL',
            },
            {
              countryCode: 'TR',
              countryName: 'Turkey',
              featured: false,
              id: 6227,
              name: 'Turkey Super Ligi',
              ref: 'TURKEY_SUPER_LIGI',
            },
            {
              featured: false,
              id: 20226,
              name: '(USA) NCAA outright',
              ref: '(USA)_NCAA_OUTRIGHT',
            },
            {
              countryCode: 'US',
              countryName: 'United States',
              featured: true,
              id: 1920,
              name: 'NBA 2019/20 ',
              ref: 'US_NBA',
            },
            {
              countryCode: 'SE',
              countryName: 'Sweden',
              featured: false,
              id: 2034,
              name: 'Sweden Ligan',
              ref: 'SWEDEN_LIGAN',
            },
            {
              countryCode: 'IS',
              countryName: 'Iceland',
              featured: false,
              id: 15705,
              name: 'Iceland Urvalsdeild Karla',
              ref: 'ICELAND_URVALSDEILD_KARLA',
            },
            {
              countryCode: 'HR',
              countryName: 'Croatia',
              featured: false,
              id: 2061,
              name: 'Croatia A1 Liga',
              ref: 'CROATIA_A1_LIGA',
            },
            {
              countryCode: 'UY',
              countryName: 'Uruguay',
              featured: false,
              id: 13985,
              name: 'Uruguay Liga Capital',
              ref: 'URUGUAY_LIGA_CAPITAL',
            },
            {
              countryCode: 'LT',
              countryName: 'Lithuania',
              featured: false,
              id: 2026,
              name: 'Lithuanian LKL',
              ref: 'LITHUANIAN_LKL',
            },
            {
              countryCode: 'RO',
              countryName: 'Romania',
              featured: false,
              id: 3917,
              name: 'Romania Divizia A',
              ref: 'ROMANIA_DIVIZIA_A',
            },
            {
              countryCode: 'DE',
              countryName: 'Germany',
              featured: false,
              id: 2046,
              name: 'Germany BBL',
              ref: 'GERMANY_BBL',
            },
            {
              countryCode: 'CH',
              countryName: 'Switzerland',
              featured: false,
              id: 2056,
              name: 'Switzerland LNA',
              ref: 'SWITZERLAND_LNA',
            },
            {
              countryCode: 'IL',
              countryName: 'Israel',
              featured: false,
              id: 2027,
              name: 'Israel Ligat Haal',
              ref: 'ISRAEL_LIGAT_HAAL',
            },
            {
              countryCode: 'ES',
              countryName: 'Spain',
              featured: false,
              id: 2059,
              name: 'Spain Adecco Oro',
              ref: 'SPAIN_ADECCO_ORO',
            },
            {
              countryCode: 'SK',
              countryName: 'Slovakia',
              featured: false,
              id: 2045,
              name: 'Slovakia Extraliga',
              ref: 'SLOVAKIA_EXTRALIGA',
            },
            {
              countryCode: 'EU',
              countryName: 'Europe',
              featured: false,
              id: 1513,
              name: 'Euroleague',
              ref: 'EUROLEAGUE',
            },
            {
              countryCode: 'GR',
              countryName: 'Greece',
              featured: false,
              id: 1992,
              name: 'Greece A1 Ethniki',
              ref: 'GREECE_A1_ETHNIKI',
            },
          ],
        },
      ],
      status: {
        method: '/fsb-api-rest/bet/category/BASKETBALL.fsb',
        returnCode: 'SUCCESS',
        timestamp: 1622015935285,
        value: 'Success',
      },
    },
  },
};

export const subCategorySoccerApiResponse = {
  list: {
    id: 4,
    name: 'Boxing',
    ref: 'BOXING',
  },
  favouriteList: {
    favourite: true,
    id: 20,
    name: 'Australian Rules',
    ref: 'AUSTRALIAN_RULES',
  },
  favList: [10, 2, 31, 20],
  selectedCategory: 'Football',
  subCategory: {
    category: [
      {
        id: 2,
        name: 'Football',
        ref: 'SOCCER',
        subcat: [
          {
            featured: false,
            id: 4719,
            name: 'Sam Houston Race Park',
            ref: 'QSH',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 741,
            name: 'Turf Paradise',
            ref: 'XTP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 698,
            name: 'Delta Downs',
            ref: 'XDD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 761,
            name: 'Ludlow',
            ref: 'LUD',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 595,
            name: 'Newcastle',
            ref: 'NCS',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1494,
            name: 'Vaal',
            ref: 'VAA',
          },
          {
            featured: false,
            id: 4426,
            name: 'Mornington',
            ref: 'MGT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 804,
            name: 'Santa Anita',
            ref: 'XST',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 711,
            name: 'Aqueduct',
            ref: 'XAQ',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 701,
            name: 'Tampa Bay Downs',
            ref: 'XTM',
          },
          {
            featured: false,
            id: 4501,
            name: 'Canberra',
            ref: 'CBR',
          },
          {
            featured: false,
            id: 4048,
            name: 'Pau',
            ref: 'PAU',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 802,
            name: 'Santa Anita',
            ref: 'QST',
          },
          {
            featured: false,
            id: 4357,
            name: 'Mauquenchy',
            ref: 'MAQ',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1513,
            name: 'Moonee Valley',
            ref: 'MNV',
          },
          {
            countryCode: 'ZA',
            countryName: 'South Africa',
            featured: false,
            id: 1511,
            name: 'Fairview',
            ref: 'FAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 1412,
            name: 'Sam Houston Race Park',
            ref: 'XSH',
          },
          {
            featured: false,
            id: 3885,
            name: 'Port Macquarie',
            ref: 'PMQ',
          },
          {
            featured: false,
            id: 3889,
            name: 'Pornichet',
            ref: 'POR',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 641,
            name: 'Lingfield',
            ref: 'LIN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 586,
            name: 'Taunton',
            ref: 'TAU',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 752,
            name: 'Ante Post',
            ref: 'AP',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 776,
            name: 'Haydock',
            ref: 'HDO',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 798,
            name: 'Wincanton',
            ref: 'WCN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 588,
            name: 'Chelmsford City',
            ref: 'CFC',
          },
          {
            featured: false,
            id: 2729,
            name: 'Saint-Cloud',
            ref: 'SCC',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 596,
            name: 'Dundalk',
            ref: 'DUN',
          },
          {
            featured: false,
            id: 7129,
            name: 'Penn National',
            ref: 'XPN',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 772,
            name: 'Ascot',
            ref: 'ASC',
          },
          {
            countryCode: 'IE',
            countryName: 'Ireland',
            featured: false,
            id: 695,
            name: 'Navan',
            ref: 'NAV',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 803,
            name: 'Golden Gate Fields',
            ref: 'XGG',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 704,
            name: 'Charles Town',
            ref: 'QCT',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 706,
            name: 'Laurel Park',
            ref: 'XLP',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 700,
            name: 'Penn National',
            ref: 'QPN',
          },
          {
            featured: false,
            id: 4465,
            name: 'Yonkers',
            ref: 'QYO',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 702,
            name: 'Gulfstream',
            ref: 'XGF',
          },
          {
            featured: false,
            id: 4479,
            name: 'Dalby',
            ref: 'DBY',
          },
          {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            featured: false,
            id: 625,
            name: 'Musselburgh',
            ref: 'MUS',
          },
          {
            countryCode: 'US',
            countryName: 'United States',
            featured: false,
            id: 801,
            name: 'Golden Gate Fields',
            ref: 'QGG',
          },
          {
            featured: false,
            id: 10340,
            name: 'The Meadows Harness',
            ref: 'XMH',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1512,
            name: 'Canterbury',
            ref: 'CBY',
          },
          {
            featured: false,
            id: 3939,
            name: 'Vincennes',
            ref: 'VIN',
          },
          {
            featured: false,
            id: 4370,
            name: 'Cagnes-sur-Mer',
            ref: 'CAG',
          },
          {
            countryCode: 'AU',
            countryName: 'Australia',
            featured: false,
            id: 1639,
            name: 'Esperance',
            ref: 'ESP',
          },
        ],
      },
    ],
    status: {
      method: '/fsb-api-rest/bet/category/HORSES.fsb',
      returnCode: 'SUCCESS',
      timestamp: 1611232311844,
      value: 'Success',
    },
  },
};

export const categoryFavorite = {
  data: {
    liveEventCount: 89,
    cookies: {
      favouriteList: '{"DARTS":["CMB","L180_YN_LM"],"SOCCER":["1X2BTTS","DC"]}',
      AppSession: 'AppSession',
    },
  },
};
