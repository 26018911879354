import styled, { css } from 'styled-components';

import {
  CLN,
  IS,
  S,
  CNI,
  SC,
  SS,
  CA,
} from 'CORE__UI/navigation/CategoryListNode/core__categoryListNodeStyles';
import { FONT, GREYS, SHADOW } from '../globals/colours';

const CLN_EX = styled(CLN)`
  ${({ active, type }) =>
    active &&
    type !== 'subcat' &&
    css`
      background-color: ${({ theme }) =>
        theme.dark ? GREYS.silverD : GREYS.white};
      box-shadow: ${SHADOW};
      font-weight: 800;
      color: ${FONT.light};
    `}
  ${({ active, type }) =>
    active &&
    type === 'subcat' &&
    css`
      font-weight: 800;
      color: ${FONT.light};
      background-color: ${({ theme }) =>
        theme.dark ? GREYS.silverD : GREYS.white};
    `}
`;

const CA_EX = styled(CA)`
  &:after {
    border-color: ${({ active }) =>
      active ? `${FONT.light}` : `${FONT.light}`};
  }
  ${({ expanded }) =>
    expanded &&
    css`
      &:after {
        transform: rotate(180deg);
        top: 8px;
        right: 8px;
      }
    `}
`;
export { CLN_EX as CLN, IS, S, CNI, SC, SS, CA_EX as CA };
