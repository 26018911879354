import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { Translations } from './core__top-leagues-app';

export const removeKeyWithoutMutatingObj = (obj, key) => {
  // eslint-disable-next-line no-unused-vars
  const { [key]: omit, ...res } = obj;
  return res;
};

export const restructureLeagues = (res, tempSortBy) => {
  const [resFt, resAtoZ] = res;
  const resAll = resAtoZ ? resAtoZ : resFt;
  let countryList = {},
    worldList = {},
    topLeagueList = {},
    sortByList = {};
  const restOfWorld = Translations.get('rest_of_world');
  const topLeagues = GLOBAL_CONSTANTS.TOP_LEAGUES;
  const mapSortByToCountryName = {};

  resAll?.category?.forEach(sport => {
    sport?.subcat?.forEach((league, index) => {
      if (league.countryName) {
        if (Object.keys(countryList).indexOf(league.countryName) === -1) {
          countryList = {
            ...countryList,
            ...{ [league.countryName]: { list: [league], isCollapsed: false } },
          };
        } else {
          countryList[league.countryName]?.list?.push(league);
        }
      } else {
        if (Object.keys(worldList).indexOf(restOfWorld) === -1) {
          worldList = {
            ...worldList,
            ...{ [restOfWorld]: { list: [league], isCollapsed: false } },
          };
        } else {
          worldList[restOfWorld]?.list?.push(league);
        }
      }
      if (!resFt) {
        if (league.featured) {
          if (Object.keys(topLeagueList).indexOf(topLeagues) === -1) {
            topLeagueList = {
              ...topLeagueList,
              ...{ [topLeagues]: { list: [league], isCollapsed: false } },
            };
          } else {
            topLeagueList[topLeagues]?.list?.push(league);
          }
        }
      }
      if (index === sport?.subcat?.length - 1 && tempSortBy.length > 0) {
        sortByList = tempSortBy?.reduce((result, current) => {
          if (sortByList[mapSortByToCountryName[current]]) {
            result[mapSortByToCountryName[current]] =
              sortByList[mapSortByToCountryName[current]];
          }
          return result;
        }, {});
      }
    });
  });

  resFt?.category?.forEach(sport => {
    sport?.subcat?.forEach(league => {
      if (league.featured) {
        if (Object.keys(topLeagueList).indexOf(topLeagues) === -1) {
          topLeagueList = {
            ...topLeagueList,
            ...{ [topLeagues]: { list: [league], isCollapsed: false } },
          };
        } else {
          topLeagueList[topLeagues]?.list?.push(league);
        }
      }
    });
  });

  return { topLeagueList, countryList, worldList, sortByList };
};
