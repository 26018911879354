import styled from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

export const CM = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  padding-left: 48px;
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.black)};
`;

export const CMC = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
`;

export const CMS = styled.span`
  padding-left: 0px;
`;
