import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

//span to display league name
export const SP = styled.span`
  background-color: ${GREYS.black};
  color: ${GREYS.white};
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
`;

//popover container span
export const PO = styled.span`
  padding: 8px 12px;
  &[data-popper-placement^='top'] #arrow {
    bottom: 4px;
  }
  &[data-popper-placement^='right'] #arrow {
    left: -4px;
  }
`;

//span for arrow properties to display
export const ARR = styled.span`
  position: absolute;
  width: 8px;
  background-color: inherit;
  visibility: hidden;
  left: calc(50% - 4px);
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: inherit;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;
