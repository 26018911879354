import React, { useEffect, useState } from 'react';
import { ListContent } from './components/ListContent';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PropTypes from 'prop-types';
import { closeCategory, handleClickOutside } from '../core__category-utils';
import { getCookie } from 'Services/cookie/core__cookies';
import { ML } from 'UI/apps/CategoryListApp/CategoryListReact';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { FSBTheme } from 'Services/core__fsb-theme';
import LeftDrawerMenuListReact from './components/SelectedMenuList/core__LeftDrawerMenuListReact';
import { SiteURL } from 'Services/core__site-url';
import project from '../../../../project';

let categoryName = '';
let showMoreState = true;

/**
 * this component contains the category list and sub categories list
 * @param {*} subCategory function for getting the sub category data
 * @param {*} fetchData function for getting the category list
 * @param {*} category Object containing the category data
 * @param {*} storeFavList function to store the fav list from server cookie
 */

export const CategoryListAppReact = ({
  categoryList,
  subCategory,
  fetchData,
  category,
  url,
  appConfig,
  starredList,
  setAccessToken,
  storeFavList,
  excludeCategory,
  accessToken,
  featuredList,
  activeMenu: activeRoute,
  navigationList,
}) => {
  const { changelistonselectedmenu, menurouteslist } = appConfig || {};
  const [selectedSports, setSelectedSports] = useState('');
  const [activeMenu, setActiveMenu] = useState(GLOBAL_CONSTANTS.SPORTSBOOK);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [limitCategoryCount, setLimitCategoryCount] = useState(10);
  const { data: { category: { liveEventCounter } = {} } = {} } = category || {};

  //to show list of items based on selected menu
  useEffect(() => {
    if (changelistonselectedmenu) {
      navigationList(appConfig);
      //if route has selected menu in params otherwise show sportsbook items
      menurouteslist?.includes(activeRoute || '')
        ? setActiveMenu(activeRoute)
        : setActiveMenu(GLOBAL_CONSTANTS.SPORTSBOOK);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Calling useEffect when ever the component is loaded
  useEffect(() => {
    const updateCategoryListSelection = PubSub.listen(
      PubsubEvents.UpdateCategoryListSelection,
      wsData => {
        setSelectedSports(wsData.category);
        wsData.subcat &&
          setSelectedCategory(wsData.countryName || 'Rest of the world');
        setSelectedSubCategory(wsData.subcat);

        //fetch subcat, if not disabled from appconfig
        if (!appConfig.withoutsubcat && categoryName !== wsData.category) {
          categoryName = wsData.category;
          subCategory(wsData.category, appConfig);
        }
        // condition to check to show more or show less on intitial load
        if (
          categoryList?.category?.length > 0 &&
          wsData.category &&
          showMoreState
        ) {
          const selectedCatIndex = categoryList?.category?.findIndex(
            x => x.ref === wsData.category
          );
          if (selectedCatIndex > limitCategoryCount) {
            showMoreState = false;
            setLimitCategoryCount(categoryList?.category?.length - 1);
          }
        }
      }
    );
    // Pubsub event listen when the session is destroyed
    const destroySession = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      // Function for sending the updated access token to store
      setAccessToken(null);
      if (
        category?.data?.cookies?.favouriteList &&
        category?.data?.category?.category
      ) {
        storeFavList(category?.data?.category, [], appConfig);
      }
    });

    // Pubsub event listen when the session is being updated
    const updateSession = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      wsData => {
        // Function for sending the updated access token to store
        setAccessToken(wsData.accessToken);
      }
    );
    // Pubsub event listen when the session is being created
    const createSession = PubSub.listen(PubsubEvents.SESSION_CREATE, wsData => {
      // Function for sending the access token to store
      setAccessToken(wsData.accessToken);
      const listCategory = getCookie('favouriteList');
      if (
        category?.data?.cookies?.favouriteList &&
        category?.data?.category?.category
      ) {
        storeFavList(
          category?.data?.category,
          listCategory ? JSON.parse(listCategory) : [],
          appConfig
        );
      }
    });
    //setting favorite list to store from server if available
    if (
      category?.data?.cookies?.favouriteList &&
      category?.data?.category?.category &&
      category?.data?.cookies?.AppSession
    ) {
      storeFavList(
        category?.data?.category,
        JSON.parse(category.data.cookies.favouriteList),
        appConfig
      );
    }
    //exclude list from cms
    if (category?.data?.category?.category && appConfig?.excludecatref) {
      excludeCategory(category?.data?.category, appConfig.excludecatref);
    }
    // exclude bandy from non gamesys partners without using CMS feed blacklisting
    if (project?.clientName !== 'gamesys') {
      excludeCategory(category?.data?.category, 'BANDY');
    }
    // function to fetch the category list data
    if (!category?.data?.category?.category)
      fetchData(
        url,
        null,
        appConfig,
        category?.data?.cookies?.favouriteList &&
          category?.data?.cookies?.AppSession
          ? JSON.parse(category.data.cookies.favouriteList)
          : [],
        appConfig?.excludecatref
      );
    // handling outside click
    document.addEventListener('mousedown', handleClickOutside);

    const closeCategorySection = document.querySelector('#close-category');
    if (closeCategorySection) {
      closeCategorySection.addEventListener('click', closeCategory);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('click', closeCategory);

      //unsubscribe from PubSubs
      updateCategoryListSelection.unsubscribe();
      destroySession.unsubscribe();
      updateSession.unsubscribe();
      createSession.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Function to match the active category list and get the sub category list according to the selected category list
  const selectCategory = (list, type) => {
    if (type === GLOBAL_CONSTANTS.CATEGORY) {
      if (list === selectedSports) {
        setSelectedSports('');
        setSelectedCategory('');
        setSelectedSubCategory('');
      } else {
        window.location.replace(`${SiteURL.path}/sportsbook/${list}/`);
      }
    } else if (type === GLOBAL_CONSTANTS.COUNTRY) {
      if (list === selectedCategory) {
        setSelectedCategory('');
        setSelectedSubCategory('');
      } else setSelectedCategory(list);
    } else {
      window.location.replace(
        `${SiteURL.path}/sportsbook/${selectedSports}/${list}/`
      );
    }
  };

  //Function to show more categories
  const showMore = value => {
    setLimitCategoryCount(value - 1);
  };
  //function to set fav category into the cookie
  const starredListing = (list, category) => {
    if (accessToken) {
      const listCategory = getCookie('favouriteList');
      const favList = JSON.parse(listCategory) || [];
      starredList(list, category, appConfig, favList);
    } else {
      PubSub.emit(PubsubEvents.openLoginModal);
    }
  };
  const theme = appConfig?.theme;
  const customTheme = appConfig?.categorytheme;
  return (
    <FSBTheme theme={customTheme ? customTheme : theme}>
      {changelistonselectedmenu && (
        <ML>
          <LeftDrawerMenuListReact
            activeMenu={activeMenu}
            liveEventCounter={liveEventCounter}
            isTranslated={appConfig?.istranslated}
          />
        </ML>
      )}
      <ML>
        {activeMenu === GLOBAL_CONSTANTS.SPORTSBOOK && (
          <ListContent
            themes={appConfig.theme}
            urlAmendments={appConfig.urlamendments}
            category={categoryList}
            subCategoryOrder={appConfig.categoryorder}
            selectedCategory={selectedCategory}
            selectCategory={selectCategory}
            limitCategoryCount={limitCategoryCount}
            showMore={showMore}
            selectedSubCategory={selectedSubCategory}
            selectedSports={selectedSports}
            starredList={starredListing}
            featuredList={featuredList}
          />
        )}
      </ML>
      {activeMenu === GLOBAL_CONSTANTS.SPORTSBOOK && (
        <div className="divider"></div>
      )}
    </FSBTheme>
  );
};

CategoryListAppReact.propTypes = {
  subCategory: PropTypes.func,
  fetchData: PropTypes.func,
  url: PropTypes.string,
  appConfig: PropTypes.object,
  category: PropTypes.object,
  starredList: PropTypes.func,
  setAccessToken: PropTypes.func,
  storeFavList: PropTypes.func,
  excludeCategory: PropTypes.func,
  categoryList: PropTypes.object,
  accessToken: PropTypes.string,
  featuredList: PropTypes.object,
  activeMenu: PropTypes.string,
  navigationList: PropTypes.func,
};

export default CategoryListAppReact;
