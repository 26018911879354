import styled, { css } from 'styled-components';
import theme from 'UI/globals/theme';
import { FONT, GREYS, SHADOW, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const CLN = styled.a`
  width: 100%;
  font-family: ${theme.fontFamily};
  font-weight: 400;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  max-width: 20em;
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.black)};
  cursor: pointer;
  transition: all 250ms ease;
  &:hover {
    font-weight: 800;
  }
  ${({ active, type }) =>
    active &&
    type !== 'subcat' &&
    css`
      background-color: ${({ theme }) =>
        theme.dark ? GREYS.silverD : GREYS.white};
      box-shadow: ${SHADOW};
      font-weight: 800;
      color: ${FONT.primary};
    `}
  // Showing active state when subcat is selected
    ${({ active, type }) =>
    active &&
    type === 'subcat' &&
    css`
      font-weight: 800;
      color: ${FONT.primary};
      background-color: ${({ theme }) =>
        theme.dark ? GREYS.silverD : GREYS.white};
    `}
    ${({ expanded, type }) =>
    expanded &&
    type === 'category' &&
    css`
      font-weight: 800;
      color: ${FONT.primary};
      background-color: ${({ theme }) =>
        theme.dark ? GREYS.silverD : GREYS.white};
    `}
    @media (max-width: ${BREAKPOINT_S}) {
    max-width: 100%;
  }
`;

// icon span
export const IS = styled.span`
  padding-right: 18px;
  font-size: 16px;
  // todo: pass type state colours to svg
`;

export const S = styled.span`
  ${({ active }) =>
    active &&
    css`
      margin-right: 12px;
    `}
`;

// country, no icon
export const CNI = styled(S)`
  padding-left: 8px;
`;

//in-play event count
export const IPC = styled(CLN)`
  padding: 0 8px 0 8px;
`;

// subcat
export const SC = styled(S)`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// star span
export const SS = styled.span`
  color: ${BRAND.favourite};
  font-size: 26px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  margin-left: auto;
  @media (max-width: ${BREAKPOINT_S}) {
    position: static;
  }
`;

// country level arrow
export const CA = styled.span`
  &:after {
    transition: all 250ms ease-out;
    content: '';
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: ${({ active }) =>
      active ? `${FONT.primary}` : `${FONT.dark}`};
    display: inline-block;
    padding: 4px;
    transform: rotate(225deg);
    position: relative;
    top: 2px;
    right: 6px;
  }
  ${({ expanded }) =>
    expanded &&
    css`
      &:after {
        transform: rotate(45deg);
        top: -2px;
      }
    `}
`;
