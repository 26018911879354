import styled, { css } from 'styled-components';
import { ANI } from 'UI/globals/motion';
import { CARD, BRAND, FONT, GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// Top leagues Listing main layout div
export const RL = styled.div(CARD);
// title div
export const FRT = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  color: ${({ theme }) => (theme.dark ? GREYS.white : BRAND.primary)};
  font-weight: bold;
  padding: 0 16px;
`;
// header main div
export const FRMD = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  margin-bottom: 4px;
  flex: 1;
  background-color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.doveL)};
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  &:last-of-type {
    border-bottom: none;
  }
  font-weight: bold;
`;
// date display div
export const FRD = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.doveD : GREYS.silver)};
  font-size: 12px;
`;
//main div
export const FRSM = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-color: ${({ theme }) => (theme.dark ? GREYS.silver : BRAND.tertiary)};
  border-bottom: 1px solid ${GREYS.silver};
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }
`;
// leagues display div
export const FRS = styled.a`
  display: flex;
  width: 33.33%;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-bottom: 4px;
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : FONT.dark)};
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }
`;
// arrow collapse
export const FRA = styled.div`
  transition: ${ANI};
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  &:after {
    content: '';
    border-style: solid;
    border-width: 0 1px 1px 0;
    color: ${GREYS.doveD};
    display: inline-block;
    padding: 4.5px;
    transform: rotate(45deg);
    position: relative;
    top: -2px;
  }
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
    `}
`;
