import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
//category app container
export const ML = styled.ul`
  width: 240px;
  padding: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }
`;
