import React from 'react';
import PropTypes from 'prop-types';
import { CMS as CMS_CORE } from 'CORE__UI/apps/CategoryListApp/core__MoreCategory';
import {
  CM,
  CMC,
  CMS as CMS_LOCAL,
} from 'UI/apps/CategoryListApp/MoreCategory';
import { Translations } from '../../../core__category-list-app';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/core__services';
import { getComponent } from 'Services/core__imports';

const CMS = getComponent(CMS_LOCAL, CMS_CORE);

/**
 * this component contains the category list and sub categories list
 * @param {*} showMore function to show more categories
 * @param {*} limitCategoryCount Number containing the number of categories to show
 * @param {*} category object containing the category list,
 * @param {*} showIcon boolean value to show icon on category
 */

export const MoreCategory = ({
  showMore,
  category,
  limitCategoryCount,
  showIcon,
}) => {
  return (
    <CMC
      onClick={() =>
        showMore(
          category?.category?.length - 1 === limitCategoryCount
            ? 10
            : category?.category.length
        )
      }
    >
      {category?.category?.length - 1 === limitCategoryCount ? (
        <CM>
          {showIcon && <I iconName={getIcon('UP')} size={16} />}
          <CMS>{Translations.get('text.cat-list.less')}</CMS>
        </CM>
      ) : (
        <CM>
          {showIcon && <I iconName={getIcon('DOWN')} size={16} />}
          <CMS>{Translations.get('text.cat-list.more')}</CMS>
        </CM>
      )}
    </CMC>
  );
};
MoreCategory.propTypes = {
  category: PropTypes.object,
  showMore: PropTypes.func,
  limitCategoryCount: PropTypes.number,
  showIcon: PropTypes.bool,
};

MoreCategory.defaultProps = {
  showIcon: false,
};

export default MoreCategory;
