import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import { restructureLeagues } from './core__top-leagues-util';

export const dataReducer = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case 'SET_FEATURED_LIST':
      newState = {
        ...state,
        featuredList: action.featuredList,
      };
      if (action?.appConfig) {
        newState['appConfig'] = action.appConfig;
      }
      return newState;
    default:
      return state;
  }
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

export const setFeaturedList = ({ featuredList, appConfig }) => ({
  type: 'SET_FEATURED_LIST',
  featuredList,
  appConfig,
});

//method to form url
export const getUrl = (sportName, appConfig) => {
  const subcatalbumref = appConfig?.subcatalbumref;
  const marketalbumref = appConfig?.marketalbumref;
  const permissivealbum = appConfig?.permissivealbum;
  let albumStrings = '?';
  if (subcatalbumref) {
    albumStrings += `&subcatAlbumRef=${subcatalbumref}`;
  }
  if (marketalbumref) {
    albumStrings += `&marketAlbumRef=${marketalbumref}`;
  }
  if (permissivealbum && (subcatalbumref || marketalbumref)) {
    albumStrings += `&permissiveAlbum=${permissivealbum}`;
  }
  if (sportName) {
    if (appConfig?.urlamendments) {
      return `/fsb-api-rest/bet/category/${sportName
        .toUpperCase()
        .replace(/-/g, '_')}.fsb${albumStrings}`;
    }
    return `/fsb-api-rest/bet/category/${sportName}.fsb${albumStrings}`;
  } else {
    return `/fsb-api-rest/bet/category.fsb${albumStrings}`;
  }
};

export const getUrlAtoZ = sportName => {
  if (sportName) {
    return `/fsb-api-rest/bet/category/${sportName
      .toUpperCase()
      .replace(/-/g, '_')}.fsb`;
  }
};

//method to get top leagues data from api
export const fetchData = (url, urlAtoZ, nodeRequest, appConfig) => dispatch => {
  const requester = !nodeRequest ? getRequest : nodeRequest;
  const tempSortBy = Array.isArray(appConfig?.sortbycountry)
    ? appConfig?.sortbycountry?.split(',')
    : [];
  const requests = appConfig?.subcatalbumref
    ? [requester(url), requester(urlAtoZ)]
    : [requester(urlAtoZ)];
  return Promise.all(requests).then(res => {
    const [resFt, resAtoZ] = res;
    const resAll = resAtoZ ? resAtoZ : resFt;
    let featuredList = {},
      countryList = {},
      topLeagueList = {},
      sortByList = {},
      restOfTheWorld = {};
    if (resAll?.category) {
      if (appConfig?.grouped === false) {
        featuredList = [...resAll?.category[0]?.subcat];
        dispatch(setFeaturedList({ featuredList, appConfig }));
      } else {
        const leagues = restructureLeagues(res, tempSortBy);
        topLeagueList = leagues.topLeagueList;
        countryList = leagues.countryList;
        sortByList = leagues.sortByList;
        restOfTheWorld = leagues.worldList;

        featuredList = {
          ...countryList,
          ...topLeagueList,
          ...sortByList,
          ...restOfTheWorld,
        };
        dispatch(setFeaturedList({ featuredList, appConfig }));
      }
    } else {
      dispatch(setFeaturedList({ featuredList, appConfig }));
    }
  });
};

//method to toggle collapse
export const countryCollapse = (featuredData, key, appConfig) => dispatch => {
  featuredData[key].isCollapsed = !featuredData[key].isCollapsed;
  const featuredList = JSON.parse(JSON.stringify(featuredData));
  dispatch(setFeaturedList({ featuredList, appConfig }));
};

const composeEnhancers = getComposeEnhancers({ name: 'top-leagues-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
