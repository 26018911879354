import React from 'react';
import PropTypes from 'prop-types';
import NoEvent from 'UI/globals/NoEvent';
import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from 'UI/apps/TopLeaguesApp/TopLeaguesPage';
import { generateNoEventsDataArray } from 'Services/global/core__noEventsArrayGenerator';
import { Translations } from '../../../core__top-leagues-app';
import { SiteURL } from 'Services/core__site-url';
import { removeKeyWithoutMutatingObj } from '../../../core__top-leagues-util';
import { SportsbookUrlService } from 'Services/url-amendments/core__url-amendments';

/**
 * this component contains the Top Leagues
 * @param {*} featuredList object containing the top leagues list
 * @param {*} fetchData function to get the top leagues data
 * @param {*} sportName string to get the selected category name
 * @param {*} countryCollapse function to toggle country section and data
 * @param {*} appConfig object to have cms configutaion
 * */

class TopLeaguesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listWithoutTopLeagues: removeKeyWithoutMutatingObj(
        props.featuredList,
        'Top Leagues'
      ),
    };
  }

  componentDidMount() {
    const positionListWithoutTopLeagues = Array.isArray(
      this.state.listWithoutTopLeagues
    )
      ? [...this.state.listWithoutTopLeagues]
      : { ...this.state.listWithoutTopLeagues };
    const removedValues = [];
    Array.isArray(this.state.listWithoutTopLeagues) &&
      this.props?.position?.map(s => {
        positionListWithoutTopLeagues.map(k => {
          if (k?.ref.includes(s)) {
            removedValues.push(
              positionListWithoutTopLeagues[
                positionListWithoutTopLeagues.indexOf(k)
              ]
            );
            positionListWithoutTopLeagues.splice(
              positionListWithoutTopLeagues.indexOf(k),
              1
            );
          }
        });
      });

    removedValues.length
      ? this.setState({
          listWithoutTopLeagues: [
            ...removedValues,
            ...positionListWithoutTopLeagues,
          ],
        })
      : this.setState({
          listWithoutTopLeagues: positionListWithoutTopLeagues,
        });
  }
  render() {
    const {
      sportName,
      appConfig,
      showFeatured,
      showAtoZ,
      featuredTitle,
      topLeagues,
      soccerRedirect,
    } = this.props;

    const category = soccerRedirect ? soccerRedirect : sportName;
    const listWithoutTopLeagues = this.state.listWithoutTopLeagues;
    return (
      <React.Fragment>
        {topLeagues?.list && topLeagues?.list?.length > 0 && showFeatured && (
          <RL>
            <FRT>
              {featuredTitle
                ? featuredTitle
                : Translations.get('text.featured')}
            </FRT>
            <FRSM>
              {topLeagues?.list?.map((league, leagueIndex) => {
                let href = `${SiteURL.path}/sportsbook/${category}/${league.ref}/`;
                if (appConfig?.urlamendments) {
                  const urlData = {
                    categoryRef: category,
                    subcategoryName: league.name,
                    subcategoryId: league.id,
                  };
                  href = SportsbookUrlService.createUrlForTheBrowser(urlData);
                }
                return (
                  <FRS title={league.name} key={leagueIndex} href={href}>
                    {league.name}
                  </FRS>
                );
              })}
            </FRSM>
          </RL>
        )}
        {(!showFeatured || showAtoZ) &&
        Object.keys(listWithoutTopLeagues).length > 0 ? (
          <RL>
            <FRT>
              {showAtoZ
                ? Translations.get('text.atoz')
                : Translations.get('text.' + appConfig?.title)}
            </FRT>
            {appConfig?.grouped === false ? (
              <FRSM>
                {Array.isArray(listWithoutTopLeagues) &&
                  listWithoutTopLeagues.map((league, leagueIndex) => {
                    let href = `${SiteURL.path}/sportsbook/${category}/${league.ref}/`;
                    if (appConfig?.urlamendments) {
                      const urlData = {
                        categoryRef: category,
                        subcategoryName: league.name,
                        subcategoryId: league.id,
                      };
                      href = SportsbookUrlService.createUrlForTheBrowser(
                        urlData
                      );
                    }
                    return (
                      <FRS title={league.name} key={leagueIndex} href={href}>
                        {league.name}
                      </FRS>
                    );
                  })}
              </FRSM>
            ) : (showFeatured && showAtoZ) || showAtoZ ? (
              <React.Fragment>
                {Object.keys(listWithoutTopLeagues).length > 0 &&
                  Object.keys(listWithoutTopLeagues)
                    .sort((a, b) => (a > b && 1) || -1)
                    .map((key, nIndex) => {
                      return (
                        <React.Fragment key={nIndex}>
                          <FRMD
                            data-test={'country-collapse' + nIndex}
                            onClick={() =>
                              this.props.countryCollapse(
                                {
                                  'Top Leagues': topLeagues,
                                  ...listWithoutTopLeagues,
                                },
                                key,
                                appConfig
                              )
                            }
                          >
                            <FRD>{key}</FRD>
                            <FRA
                              expanded={listWithoutTopLeagues[key].isCollapsed}
                            ></FRA>
                          </FRMD>
                          {!listWithoutTopLeagues[key].isCollapsed && (
                            <FRSM>
                              {listWithoutTopLeagues[key]?.list?.map(
                                (league, leagueIndex) => {
                                  let href = `${SiteURL.path}/sportsbook/${category}/${league.ref}/`;
                                  if (appConfig?.urlamendments) {
                                    const urlData = {
                                      categoryRef: category,
                                      subcategoryName: league.name,
                                      subcategoryId: league.id,
                                    };
                                    href = SportsbookUrlService.createUrlForTheBrowser(
                                      urlData
                                    );
                                  }
                                  return (
                                    <FRS
                                      title={league.name}
                                      data-test={
                                        'league' + nIndex + leagueIndex
                                      }
                                      key={leagueIndex}
                                      href={href}
                                    >
                                      {league.name}
                                    </FRS>
                                  );
                                }
                              )}
                            </FRSM>
                          )}
                        </React.Fragment>
                      );
                    })}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {Object.keys(listWithoutTopLeagues).length > 0 &&
                  Object.keys(listWithoutTopLeagues).map((key, nIndex) => {
                    return (
                      <React.Fragment key={nIndex}>
                        <FRMD
                          data-test={'country-collapse' + nIndex}
                          onClick={() =>
                            this.props.countryCollapse(
                              {
                                'Top Leagues': topLeagues,
                                ...listWithoutTopLeagues,
                              },
                              key,
                              appConfig
                            )
                          }
                        >
                          <FRD>{key}</FRD>
                          <FRA
                            expanded={listWithoutTopLeagues[key].isCollapsed}
                          ></FRA>
                        </FRMD>
                        {!listWithoutTopLeagues[key].isCollapsed && (
                          <FRSM>
                            {listWithoutTopLeagues[key]?.list?.map(
                              (league, leagueIndex) => {
                                let href = `${SiteURL.path}/sportsbook/${category}/${league.ref}/`;
                                if (appConfig?.urlamendments) {
                                  const urlData = {
                                    categoryRef: category,
                                    subcategoryName: league.name,
                                    subcategoryId: league.id,
                                  };
                                  href = SportsbookUrlService.createUrlForTheBrowser(
                                    urlData
                                  );
                                }
                                return (
                                  <FRS
                                    title={league.name}
                                    data-test={'league' + nIndex + leagueIndex}
                                    key={leagueIndex}
                                    href={href}
                                  >
                                    {league.name}
                                  </FRS>
                                );
                              }
                            )}
                          </FRSM>
                        )}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            )}
          </RL>
        ) : (
          !showFeatured && (
            <NoEvent
              title={Translations.get('text.noleagues')}
              info={Translations.get('text.helpful.links')}
              noEventData={generateNoEventsDataArray(
                this.props.appConfig?.nodatatitles,
                this.props.appConfig?.nodatalinks
              )}
            />
          )
        )}
      </React.Fragment>
    );
  }
}

TopLeaguesPage.propTypes = {
  featuredList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fetchData: PropTypes.func,
  sportName: PropTypes.string,
  countryCollapse: PropTypes.func,
  appConfig: PropTypes.object,
  showAtoZ: PropTypes.bool,
  showFeatured: PropTypes.bool,
  featuredTitle: PropTypes.string,
  topLeagues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  soccerRedirect: PropTypes.string,
  position: PropTypes.array,
};
export default TopLeaguesPage;
