import {
  LC,
  LMW,
  TN,
  CL,
} from 'CORE__UI/apps/CategoryListApp/core__ListContent';

import styled from 'styled-components';
import { BRAND, FONT, GREYS } from '../../globals/colours';

const TN_EX = styled(TN)`
  color: ${BRAND.secondary};
`;

const LC_GDWN = styled(LC)`
  color: ${props => (props.active ? `${FONT.light}` : `${FONT.dark}`)};
  &:hover {
    background-color: ${GREYS.silverD};
    color: ${FONT.light};
  }
  background-color: ${props => (props.active ? `${FONT.light}` : 'none')};
  & a {
    color: ${FONT.light};
  }
`;

export { LC_GDWN as LC, LMW, TN_EX as TN, CL };
