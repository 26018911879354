import React from 'react';
import { TopLeaguesPage } from './components/TopLeaguesPage';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { getUrl, getUrlAtoZ } from '../core__top-leagues-store';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';
import { EventsAppTitle } from 'UI/navigation/TopLink';
import { Translations } from '../core__top-leagues-app';

/**
 * this component contains the Top Leagues
 * @param {*} featuredList object containing the top leagues list
 * @param {*} fetchData function to get the top leagues data
 * @param {*} sportName string to get the selected category name
 * @param {*} appConfig object to have cms configutaion
 * */

class TopLeagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props?.appConfig?.active || !props.appConfig?.eventsHeaderActive,
      appid: props?.appConfig?.appid,
      eventsHeaderActive: props?.appConfig?.eventsHeaderActive,
      theme: props.appConfig?.theme,
      title: props?.appConfig?.title,
      showFeatured: props?.appConfig?.showfeatured,
      showAtoZ: props?.appConfig?.showatoz,
      featuredTitle: props?.appConfig?.featuredtitle,
      position: props?.appConfig?.position?.split(','),
    };
  }

  componentDidMount() {
    //Listener for checking active event
    this.listenerForEventsActive();
    if (this.state.active) {
      //emitter to update category list
      this.emitterForCategoryList();
    }

    if (this.state.eventsHeaderActive) {
      this.emitterForEventsHeader();
    }
  }

  emitterForCategoryList = () => {
    PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
      category: this.props.sportName,
    });
  };

  emitterForEventsHeader = () => {
    PubSub.emit(PubsubEvents.EventHeaderUpdate, {
      appid: this.props?.appConfig?.appid,
      title: this.props?.appConfig?.title,
    });
  };

  listenerForEventsActive = () => {
    PubSub.listen(PubsubEvents.EventsAppActive, wsData => {
      if (wsData.appid === parseInt(this?.state?.appid)) {
        this.setState({ active: true }, () => {
          this.getData();
          this.emitterForCategoryList();
        });
      } else {
        this.setState({ active: false });
      }
    });
  };
  getData = () => {
    if (!this.props.featuredList) {
      const url = getUrl(this.props.sportName, this.props.appConfig);
      const urlAtoZ = getUrlAtoZ(this.props.sportName);
      this.props.fetchData(url, urlAtoZ, null, this.props.appConfig);
    }
  };
  render() {
    return (
      (this.state.active && this.props.featuredList && (
        <FSBTheme theme={this.state.theme}>
          {!this.state.eventsHeaderActive && (
            <EventsAppTitle
              active={true}
              label={Translations.get('text.' + this.state.title)}
            />
          )}
          <TopLeaguesPage
            soccerRedirect={this.props.appConfig?.soccerredirect}
            featuredList={this.props.featuredList}
            topLeagues={this.props.featuredList['Top Leagues']}
            showFeatured={this.state.showFeatured}
            showAtoZ={this.state.showAtoZ}
            position={this.state.position}
            featuredTitle={this.state.featuredTitle}
            {...this.props}
          />
        </FSBTheme>
      )) ||
      ''
    );
  }
}

TopLeagues.propTypes = {
  appConfig: PropTypes.object,
  featuredList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sportName: PropTypes.string,
  fetchData: PropTypes.func,
};

export default TopLeagues;
