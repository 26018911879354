import {
  ND,
  W,
  P,
  A,
  LC,
  ST,
} from 'CORE__UI/globals/NoEventWithBtn/core__noEventStylesWithBtn';
import styled, { css } from 'styled-components';
import { GREYS } from './colours';
const ND_GDWN = styled(ND)`
  word-wrap: break-word;
  color: ${GREYS.white};
`;
const W_GDWN = styled(W)`
  padding: 64px;
  margin: 20px auto auto auto;
  text-align: center;
  word-break: break-all;
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      text-align: left;
      margin: 20px auto auto auto;
      background-color: ${GREYS.silverD};
    `}
`;
const ST_GDWN = styled(ST)`
  font-weight: 700;
  color: ${GREYS.white};
`;
const LC_GDWN = styled(LC)`
  padding: 0;
  margin: auto;
  background-color: inherit;
  width: inherit;
  height: inherit;
  justify-content: center;
  color: ${GREYS.white};
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      margin: 0;
      padding: 0;
      height: inherit;
      width: inherit;
      justify-content: left;
    `}
`;
const A_GDWN = styled(A)`
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: ${GREYS.white};
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      margin: 4px;
    `}
`;
const P_GDWN = styled(P)`
  color: ${GREYS.white};
  margin-block: 8px;
  margin-inline: 0;
  word-wrap: break-word;
`;
export {
  ND_GDWN as ND,
  W_GDWN as W,
  P_GDWN as P,
  A_GDWN as A,
  LC_GDWN as LC,
  ST_GDWN as ST,
};
