import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import { SP, ARR, PO } from 'UI/navigation/PopperStyles';

const Popper = ({ label, type }) => {
  // Function to display the popper on subcategory hover
  const [showToolTip, setToolTip] = useState(false);
  const getPopper = (ele, tip, view, type) => {
    const button = document.querySelector('#' + ele);
    const tooltip = document.querySelector('#' + tip);
    if (view) {
      setToolTip(true);
    } else {
      setToolTip(false);
    }
    if (type !== 'subcat') {
      setToolTip(false);
    }
    createPopper(button, tooltip, {
      placement: 'top',
    });
  };

  //method to remove special characters from leaguename for id
  const trimCharacters = str => {
    return str.replace(/[&\\#,/ `+()$~%.'":*?<>{}]/g, '');
  };

  return (
    <React.Fragment>
      <span
        id={'element' + trimCharacters(label)}
        onMouseOver={() => {
          label.length >= 22 &&
            getPopper(
              'element' + trimCharacters(label),
              'tooltip' + trimCharacters(label),
              true,
              type
            );
        }}
        onMouseLeave={() => {
          label.length >= 22 &&
            getPopper(
              'element' + trimCharacters(label),
              'tooltip' + trimCharacters(label),
              false,
              type
            );
        }}
      >
        {label}
      </span>
      <PO id={'tooltip' + trimCharacters(label)}>
        {showToolTip && (
          <SP>
            {label}
            <ARR id="arrow" data-popper-arrow></ARR>
          </SP>
        )}
      </PO>
    </React.Fragment>
  );
};

Popper.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Popper;
