import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import Popper from 'UI/navigation/Popper';
import {
  CLN,
  IS,
  S,
  CNI,
  SC,
  SS,
  CA,
} from 'UI/navigation/CategoryListNodeStyles';
import { withTheme } from 'styled-components';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import clientDetails from '../../../../../src/project';
import { SiteURL } from 'Services/core__site-url';
import { SportsbookUrlService } from 'Services/url-amendments/core__url-amendments';

let isScroll = false;
let subScroll = false;
const typeSetter = (type, icon) => {
  switch (type) {
    case 'country':
      if (icon && icon !== '') {
        return S;
      } else {
        return CNI;
      }

    case 'subcat':
      return SC;

    default:
      return S;
  }
};

const CategoryListNode = ({
  active,
  label,
  icon,
  starred,
  type,
  expanded,
  path,
  list,
  categoryList,
  categories,
  selectedSports,
  selectedCategory,
  selectedSubCategory,
  bespokeSvgSportsIcons,
  urlAmendments,
  ...props
}) => {
  // added logic for scroll to selected category on refresh
  const clnRef = useRef(null);
  const checkClientDetails =
    clientDetails.clientName !== 'gamesys' || !clientDetails.clientName;
  const loadScroll = () => {
    setTimeout(() => {
      clnRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 500);
  };
  if (clnRef && checkClientDetails) {
    if (!isScroll && list === selectedSports) {
      isScroll = true;
      loadScroll();
    }
    if (!subScroll && list === selectedSubCategory) {
      subScroll = true;
      loadScroll();
    }
  }
  // Function to get the favorite category
  const favoriteCategory = (list, e) => {
    e.stopPropagation();
    // Function with category list ref
    props.starredList(list, categories);
  };
  const iconName =
    list === GLOBAL_CONSTANTS.BETTING_POOLS
      ? GLOBAL_CONSTANTS.FOOTBALL_ICON
      : getIcon(list);
  let favoriteCategoryStar = '&#9734;';
  if (starred && type === 'category') {
    favoriteCategoryStar = '&#9733;';
  }
  const routePath = () => {
    let path = `${GLOBAL_PATH.SPORTS_BOOK}${selectedSports}/${list}/`;
    if (type === GLOBAL_CONSTANTS.CATEGORY) {
      path = `${GLOBAL_PATH.SPORTS_BOOK}${list}/`;
      if (list === selectedSports) {
        path = '#';
      }
      if (list === GLOBAL_CONSTANTS.BETTING_POOLS) {
        path = `/${list}/`;
      }
    }
    if (type === GLOBAL_CONSTANTS.COUNTRY || list === selectedCategory) {
      path = '#';
    }
    return path;
  };
  const urlData = {
    categoryRef: routePath()?.split('/sportsbook/'),
  };
  const href = urlAmendments
    ? SportsbookUrlService.createUrlForTheBrowser(urlData)
    : SiteURL.path + routePath();

  return (
    <Fragment>
      <CLN
        ref={clnRef}
        href={href}
        title={label}
        active={active}
        onClick={() => path(list, type)}
        type={type}
        {...props}
        data-testid="category-list-node"
        expanded={expanded}
      >
        <S as={typeSetter(type, icon)} ref={clnRef} active={active}>
          {icon && type !== 'subcat' && <IS>{icon}</IS>}
          {type === 'category' && (
            <S active={true}>
              <I
                iconName={iconName}
                bespokeSvgSportsIcons={bespokeSvgSportsIcons}
                category={list}
                active={expanded}
              />
            </S>
          )}
          <Popper label={label} type={type} />
        </S>
        {/* expandable arrow */}
      </CLN>
      <SS
        data-testid="star-test"
        data-favourite={categoryList?.favourite}
        favourite={categoryList?.favourite}
        dangerouslySetInnerHTML={{
          __html: favoriteCategoryStar,
        }}
        onClick={e => favoriteCategory(categoryList, e)}
        starred={starred}
      />
      {type === 'country' && <CA active={active} expanded={expanded}></CA>}{' '}
    </Fragment>
  );
};

CategoryListNode.propTypes = {
  type: PropTypes.string,
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  starred: PropTypes.bool,
  expanded: PropTypes.bool,
  path: PropTypes.func,
  list: PropTypes.string,
  categoryList: PropTypes.object,
  categories: PropTypes.object,
  selectedCategory: PropTypes.string,
  starredList: PropTypes.func,
  selectedSports: PropTypes.string,
  selectedSubCategory: PropTypes.string,
  bespokeSvgSportsIcons: PropTypes.bool,
  urlAmendments: PropTypes.string,
};

export default withTheme(CategoryListNode);
