import styled from 'styled-components';
import { BRAND } from 'UI/globals/colours';
import theme from 'UI/globals/theme';

export const ND = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: ${BRAND.primary};
`;
export const W = styled.div`
  text-align: center;
  margin: 56px auto;
  font-family: ${theme.fontFamily};
`;
export const P = styled.p`
  margin: 8px auto;
`;
export const A = styled.a`
  color: ${BRAND.primary};
`;

//Extension for LC
export const LC = styled.div`
  width: 337px;
  height: 48px;
  border-radius: 4px;
  background-color: ${BRAND.primaryL};
  display: flex;
  padding: 8px;
  color: ${BRAND.primary};
  margin: 12px 0 12px;
`;
export const ST = styled.h2`
  color: ${BRAND.primary};
`;
