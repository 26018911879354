export const stringsToTranslate = [
  'markets.viewmore',
  'text.helpful.links',
  'text.featured',
  'text.atoz',
  'text.noleagues',
  'text.livenow',
  'text.upcoming',
  'text.competitions',
  'text.inplay',
  'text.outrights',
  'rest_of_world',
];
