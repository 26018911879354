import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MoreCategory } from '../MoreCategory/index';
import CategoryListNode from 'UI/navigation/CategoryListNode';
import { ExcludeList } from '../../../core__response.stub.';
import { LC, LMW, TN, CL } from 'UI/apps/CategoryListApp/ListContent';
import { Translations } from '../../../core__category-list-app';
import project from '../../../../../../project';
/**
 * this component contains the category list and sub categories list
 * @param {*} subCategoryOrder String containing the sub category list order
 * @param {*} selectedCategory string containing the active category which is selected
 * @param {*} selectedSubCategory string containing the active sub category which is selected
 * @param {*} showMore function to show more categories
 * @param {*} limitCategoryCount Number containing the number of categories to show
 * @param {*} selectCategory function to set the category to active
 * @param {*} category object containing the category list
 * @param {*} featuredList object containing the top leagues category list
 */

export const ListContentComponent = ({
  category,
  subCategoryOrder,
  selectCategory,
  selectedCategory,
  limitCategoryCount,
  showMore,
  selectedSports,
  selectedSubCategory,
  starredList,
  featuredList,
  urlAmendments,
}) => {
  const { subCategory } = useSelector(state => ({
    subCategory: state.data.subCategory,
  }));
  const [filteredSubCategories, setFilteredSubCategories] = useState(
    subCategory || {}
  );
  const [topCategories, setTopCategories] = useState({});
  const [topLeagues, setTopLeagues] = useState({});

  useEffect(() => {
    if (subCategory) {
      const subCategoriesList = subCategoryOrder.split(',');

      const subCategories = filterSubCat(
        subList => !subCategoriesList.includes(subList)
      );
      setFilteredSubCategories(subCategories);

      const topCats = filterSubCat(subList =>
        subCategoriesList.includes(subList)
      );
      setTopCategories(topCats);
    }
    if (featuredList) {
      const topLeagues = buildTopLeagues();
      setTopLeagues(topLeagues);
    }
  }, [subCategory, featuredList]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Filters subcateries depending if they're main or all, main comes from the subCategoryOrder prop
   * @param {*} condition fuction to that must retun a boolean
   * @returns
   */
  const filterSubCat = condition => {
    return Object.keys(subCategory).reduce((result, subList) => {
      if (condition(subList)) {
        result[subList] = subCategory[subList];
      }
      return result;
    }, {});
  };

  const buildTopLeagues = () => {
    const topLeagues = featuredList['Top Leagues']?.list;
    const topLeaguesBuilt = {};
    if (topLeagues && topLeagues.length > 0) {
      topLeaguesBuilt['Top Leagues'] = topLeagues;
    } else {
      topLeagues.list = [];
    }
    return topLeaguesBuilt;
  };

  return (
    <React.Fragment>
      <TN>{Translations.get('text.sports')}</TN>
      {/* checking the category object has categories or not */}
      {category?.category && (
        <React.Fragment>
          {/* Looping through the category array to show the categories name */}
          {category?.category?.map(
            (list, index) =>
              // Limiting the category list count to maximum 10 when the page loaded
              index <= limitCategoryCount &&
              list.name !== ExcludeList && (
                <LMW key={list.id}>
                  <LC
                  // calling Category list components with all the sports list
                  >
                    <CategoryListNode
                      active={false}
                      label={list.name}
                      icon={''}
                      starred={list.favourite}
                      type="category"
                      expanded={list.ref === selectedSports}
                      path={selectCategory}
                      list={list.ref}
                      starredList={starredList}
                      categories={category}
                      categoryList={list}
                      selectedSports={selectedSports}
                      selectedSubCategory={selectedSubCategory}
                      bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
                      urlAmendments={urlAmendments}
                    />
                  </LC>
                  {/* Rendering the top leagues incoming from api list */}
                  {subCategory &&
                    selectedSports === list.ref &&
                    topLeagues &&
                    Object.keys(topLeagues)?.map(subList => (
                      <CL key={subList}>
                        {/* calling Category list components with all the sub category (countries) list */}
                        <CategoryListNode
                          active={subList === selectedCategory}
                          label={subList}
                          icon={''}
                          starred={false}
                          type="country"
                          expanded={subList === selectedCategory}
                          path={selectCategory}
                          list={subList}
                          selectedSports={selectedSports}
                          selectedSubCategory={selectedSubCategory}
                          urlAmendments={urlAmendments}
                        />
                        {selectedCategory === subList &&
                          topLeagues &&
                          topLeagues[subList]?.map(event => (
                            // calling Category list components with all the event associated with the sports and country
                            <CategoryListNode
                              key={event.ref}
                              active={selectedSubCategory === event.ref}
                              label={event.name}
                              icon={''}
                              starred={false}
                              type="subcat"
                              expanded={false}
                              path={selectCategory}
                              list={event.ref}
                              selectedSports={selectedSports}
                              selectedSubCategory={selectedSubCategory}
                              urlAmendments={urlAmendments}
                            />
                          ))}
                      </CL>
                    ))}
                  {/* Looping for rendering the top sub category (countries) list */}
                  {subCategory &&
                    selectedSports === list.ref &&
                    topCategories &&
                    Object.keys(topCategories)?.map(subList => (
                      <CL key={subList}>
                        {/* calling Category list components with all the sub category (countries) list */}
                        <CategoryListNode
                          active={subList === selectedCategory}
                          label={subList}
                          icon={''}
                          starred={false}
                          type="country"
                          expanded={subList === selectedCategory}
                          path={selectCategory}
                          list={subList}
                          selectedSports={selectedSports}
                          selectedSubCategory={selectedSubCategory}
                          urlAmendments={urlAmendments}
                        />
                        {selectedCategory === subList &&
                          topCategories &&
                          topCategories[subList]?.map(event => (
                            // calling Category list components with all the event associated with the sports and country
                            <CategoryListNode
                              key={event.ref}
                              active={selectedSubCategory === event.ref}
                              label={event.name}
                              icon={''}
                              starred={false}
                              type="subcat"
                              expanded={false}
                              path={selectCategory}
                              list={event.ref}
                              selectedSports={selectedSports}
                              selectedSubCategory={selectedSubCategory}
                              urlAmendments={urlAmendments}
                            />
                          ))}
                      </CL>
                    ))}
                  {/* Looping for rendering the sub category (countries) list */}
                  {subCategory &&
                    selectedSports === list.ref &&
                    Object.keys(filteredSubCategories)?.map(subList => (
                      <CL key={subList}>
                        {/* calling Category list components with all the sub category (countries) list */}
                        <CategoryListNode
                          active={subList === selectedCategory}
                          label={subList}
                          icon={''}
                          starred={false}
                          type="country"
                          expanded={subList === selectedCategory}
                          path={selectCategory}
                          list={subList}
                          selectedSports={selectedSports}
                          selectedSubCategory={selectedSubCategory}
                          urlAmendments={urlAmendments}
                        />
                        {selectedCategory === subList &&
                          subCategory[subList]?.map(event => (
                            // calling Category list components with all the event associated with the sports and country
                            <CategoryListNode
                              key={event.ref}
                              active={selectedSubCategory === event.ref}
                              label={event.name}
                              icon={''}
                              starred={false}
                              type="subcat"
                              expanded={false}
                              path={selectCategory}
                              list={event.ref}
                              selectedSports={selectedSports}
                              selectedSubCategory={selectedSubCategory}
                              urlAmendments={urlAmendments}
                            />
                          ))}
                      </CL>
                    ))}
                </LMW>
              )
          )}
          {/* Rendering the more category component when user clicks on more category */}
          <MoreCategory
            showMore={showMore}
            category={category}
            limitCategoryCount={limitCategoryCount}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export function mapStateToProps(state) {
  return {
    subCategory: state.subCategory,
  };
}

ListContentComponent.propTypes = {
  selectedCategory: PropTypes.string,
  selectCategory: PropTypes.func,
  showMore: PropTypes.func,
  limitCategoryCount: PropTypes.number,
  category: PropTypes.object,
  selectedSports: PropTypes.string,
  selectedSubCategory: PropTypes.string,
  starredList: PropTypes.func,
  featuredList: PropTypes.object,
  subCategoryOrder: PropTypes.string,
  urlAmendments: PropTypes.string,
};

export default connect(mapStateToProps)(ListContentComponent);
