import React from 'react';
import PropTypes from 'prop-types';
import { ND, W, P, A, LC, ST } from 'UI/globals/NoEventStyles';

/**
 *
 * @param {string} title
 * @param {string} info
 * @param {Array} noEventData
 * @returns a UI component for when there is no event available
 */

const NoEvent = ({
  title,
  subTitle,
  info,
  noEventData,
  showJNStyle,
  Translations,
}) => {
  const checkTarget = link => {
    try {
      const url = new URL(link);
      if (window.location.host === url.host) {
        return '_parent';
      }
      return '_self';
    } catch (e) {
      return '_self';
    }
  };

  return (
    <W showJNStyle={showJNStyle}>
      <ND>{title}</ND>
      <ST>{subTitle}</ST>
      <P showJNStyle={showJNStyle}>{info}</P>
      {noEventData?.map((eventName, id) => (
        <LC showJNStyle={showJNStyle} key={id}>
          <A
            showJNStyle={showJNStyle}
            href={eventName.link}
            target={checkTarget(eventName.link)}
          >
            {Translations.get(
              'text.' + eventName.name.toLowerCase().replace(/ /g, '')
            )}
          </A>
          <br />
        </LC>
      ))}
    </W>
  );
};
NoEvent.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  info: PropTypes.string,
  noEventData: PropTypes.array,
  showJNStyle: PropTypes.bool,
  Translations: PropTypes.object,
};

export default NoEvent;
