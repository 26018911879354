import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import createStore, {
  countryCollapse,
  fetchData,
} from './core__top-leagues-store';
import TopLeagues from '../TopLeaguesApp/component/core__TopLeaguesReact';
import ScDecoder from 'Services/json/core__decoder';
import { getUrl } from './core__top-leagues-store';
import { checkEventsHeaderAppActive } from 'Services/global/core__entry-file-utils';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { stringsToTranslate } from './core__top-leagues-translations';
export const Translations = new TranslationsProvider('TopLeaguesApp');

class TopLeaguesApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    if (this.appConfig) {
      this.appConfig.eventsHeaderActive = checkEventsHeaderAppActive(
        this.appSection
      );
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const sportName = this.appConfig?.category
      ? this.appConfig?.category
      : this.appConfig.requestURL.split('/')[2];
    const url = getUrl(sportName);
    const mapStateToProps = state => ({
      featuredList: state.data.featuredList,
      sportName: sportName,
      appConfig: this.appConfig,
    });

    const mapDispatchToProps = {
      countryCollapse: countryCollapse,
      fetchData: fetchData,
    };

    TopLeagues.serverFetch = {
      fetchData: fetchData,
      createStore,
      url,
      strToTranslate: stringsToTranslate,
    };

    return connect(mapStateToProps, mapDispatchToProps)(TopLeagues);
  }
}

export default TopLeaguesApp;
