import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from 'CORE__UI/apps/TopLeaguesApp/core__topLeaguesPage';
import styled, { css } from 'styled-components';
import { GREYS, FONT } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';
const RL_GDWN = styled(RL)`
  box-shadow: none;
`;
const FRT_GDWN = styled(FRT)`
  color: ${GREYS.white};
  border-color: ${FONT.dark};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;
const FRMD_GDWN = styled(FRMD)`
  border-color: ${FONT.dark};
  background-color: ${GREYS.silverD};
`;
const FRS_GDWN = styled(FRS)`
  color: ${GREYS.white};
`;
const FRD_GDWN = styled(FRD)`
  color: ${FONT.secondary};
  font-weight: 700;
`;
const FRA_GDWN = styled(FRA)`
  transform: rotate(180deg);
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(0deg);
    `}
`;
const FRSM_GDWN = styled(FRSM)`
  border-color: ${FONT.dark};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;
export {
  RL_GDWN as RL,
  FRT_GDWN as FRT,
  FRMD_GDWN as FRMD,
  FRD_GDWN as FRD,
  FRSM_GDWN as FRSM,
  FRS_GDWN as FRS,
  FRA_GDWN as FRA,
};
